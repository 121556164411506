<template>
    <div class="news">
        <swiper id="banner" ref="banner" class="swiper banner" :options="bannerOPtions">
            <swiper-slide v-for="item in banners" :key="item.id">
                <auto-img :src="item.img" mul="29%" :path="item.url"></auto-img>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination" v-siwperPagination></div>
        </swiper>

        <el-container class="main mainGride" :class="{ mainDetail: name === 'NewsDetail' }">
            <el-aside width="216px">
                <div class="menu" :style="{ transform: `translateY(${top}px)` }">
                    <div
                        class="menu-item"
                        v-for="(item, index) in InformationTypes"
                        :key="index"
                        @click="chooseMenu(item.key)"
                        :class="{ active: active === item.key }"
                    >
                        {{ item.name }}
                    </div>
                </div>
            </el-aside>
            <keep-alive include="NewsList">
                <router-view ref="child" />
            </keep-alive>
        </el-container>
    </div>
</template>
<script>
import { InformationType } from '../../utils/AppState';
import { getBanner } from '../../utils/CommonApi';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
export default {
    name: 'NewsMain',
    data() {
        return {
            banners: [],
            bannerOPtions: {
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                speed: 600,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'banner-bullet',
                    bulletActiveClass: 'banner-bullet-active'
                }
            },
            active: 0,
            fixed: false,
            top: 0
        };
    },
    computed: {
        InformationTypes() {
            return [...InformationType].map(item => {
                return {
                    key: item[0],
                    name: this.$i18n.t(item[1].name),
                    type: item[1].type
                };
            });
        },
        type() {
            return InformationType.has(this.active) ? InformationType.get(this.active).type : '';
        },
        name() {
            return this.$route.name || '';
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    watch: {
        $route() {
            if (this.active !== this.$route.query.type && !this.$route.query.id) {
                this.chooseMenu(this.$route.query.type || '', true);
            }
        },
        isChinese() {
            let keys = [...InformationType]
                .map(item => {
                    let items = item[0].split(',');
                    console.log(items);

                    return items.length > 1 ? items : items[0];
                })
                .flat();
            console.log(keys);

            getBanner(this.active || [...new Set(keys)], !this.isChinese).then(res => {
                this.banners = res;
                this.$nextTick(() => {
                    this.$refs.banner.$swiper.update();
                });
            });
        }
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === 'NewsDetail') {
            this.$store.commit('updateKeepAlive', ['NewsMain']);
        } else {
            this.$store.commit('updateKeepAlive', []);
        }
        setTimeout(() => {
            next();
        }, 100);
    },
    mounted() {
        if (this.$route || this.$route.name === 'NEWS') {
            this.chooseMenu(this.$route.query.type || '', true);
        } else if (this.active !== this.$route.query.type) {
            this.chooseMenu(this.$route.query.type, true);
        }
        window.addEventListener('scroll', this.scrollEvent);
        window.addEventListener('resize', this.scrollEvent);
    },
    methods: {
        chooseMenu(key, init) {
            console.log('aaa');

            this.active = key;

            if (!init) {
                this.$router.replace({
                    name: 'NEWS',
                    query: {
                        type: key
                    }
                });
            }
            let keys = [...InformationType]
                .map(item => {
                    let items = item[0].split(',');
                    console.log(items);

                    return items.length > 1 ? items : items[0];
                })
                .flat();

            getBanner(this.active || [...new Set(keys)], !this.isChinese).then(res => {
                this.banners = res;
                this.$nextTick(() => {
                    this.$refs.banner.$swiper.update();
                });

                document.documentElement.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            });
        },
        scrollEvent() {
            if (document.documentElement.scrollTop >= document.getElementById('banner').offsetHeight - 90) {
                this.top = document.documentElement.scrollTop - document.getElementById('banner').offsetHeight + 90;
            } else {
                this.top = 0;
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollEvent);
        window.removeEventListener('resize', this.scrollEvent);
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.banner {
    min-height: 29vw;
}
.list {
    padding: 0 30px;
    min-height: 50vh;
}
.main {
    padding-top: 40px;
    padding-bottom: 40px;
    @include center-content(246);
    // max-width: 1200px;
    box-sizing: border-box;
}
// .floatMenu {
//     position: fixed;
//     z-index: 20;
//     top: 120px;
//     width: 216px;
//     // @include center-content-left();
// }
// .mainGride {
//     @include center-content(347);
// }

.mainDetail {
    @include center-content(347);
}

.el-container {
    overflow: hidden;
}

.menu {
    background: #ffffff;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    color: #818999;
    line-height: 80px;

    .menu-item {
        height: 80px;
        cursor: pointer;
        position: relative;

        &:hover {
            color: #ffa526;
        }

        &.active {
            background: #292c33;
            color: #ffa526;
            &::before {
                position: absolute;
                left: 0;
                width: 2px;
                top: 0;
                bottom: 0;
                background-color: #ffa526;
            }
        }
    }
}

.row {
    .el-card {
        margin-bottom: 30px;
    }
}

.el-col {
    margin-bottom: 30px;
}

.el-footer {
    padding: 20px;
    display: flex;
    justify-content: center;
    .el-pagination {
        // margin: 0 auto;
    }
}
</style>
